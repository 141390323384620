import React, {Component} from 'react';
import {withStyles, Dialog, DialogContent,Button, DialogTitle, MenuItem, Icon, IconButton, Typography, Toolbar, AppBar,  InputAdornment} from '@material-ui/core';
import * as Actions from 'app/main/dealer/store/actions';
import {bindActionCreators} from 'redux';
import Formsy from 'formsy-react';
import {TextFieldFormsy, SelectFormsy} from '@fuse';
import {connect} from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import {Input, Form, Upload, Row, Col, Select} from 'antd';

import classNames from 'classnames';
import _ from '@lodash';
import { UI_MODES } from 'app/main/config';

const { Option } = Select;

var ColorPicker = require('rc-color-picker');
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
};

const styles = theme => ({
    paper: {
        color: theme.palette.text.primary
    },
    iOSSwitchBase: {
        '&$iOSChecked': {
          color: theme.palette.common.white,
          '& + $iOSBar': {
            backgroundColor: '#52d869',
          },
        },
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none',
        },
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 24,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
});

let getVisualizerName = (url) =>{
  let processUrl = url;
  const urlArray = processUrl.split(".");
  if (urlArray.length > 2 && urlArray[1] === "addovisuals" && urlArray[2].slice(0, 3) === "com") {
    return urlArray[0];
  }
  return "";
}

const newDealerState = {
    id              : '',
    companyName     : '',
    contactPerson   : '',
    address         : '',
    telephone       : '',
    visualizerName  : '',
    email           : '',
    url             : '',
    themeColor      : '',
    bcc             : '',
    fromEmail            : '',
    subject         : '',
    dealerLogo      : [],
    catalog_id      : [],
    ui_mode         : "",
    password        : '',
    dealerBack      : [],
};

class DealerDialog extends Component {
    
    state = {
        ...newDealerState,
        dueMenu: null,
        canSubmit:false,
        checked: true,
        anchorEl   : null
    };

    constructor (props) {
        super(props);
        this.props.getCatalogCategory();
    }
    handleMenuClose = () => {
        this.setState({anchorEl1: null});
    };

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        /**
         * After Dialog Open
         */
        if ( !prevProps.dealerDialog.props.open && this.props.dealerDialog.props.open )
        {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if ( this.props.dealerDialog.type === 'edit' &&
                this.props.dealerDialog.data &&
                !_.isEqual(this.props.dealerDialog.data, prevState) )
            {
                console.log('---', this.props.dealerDialog.data);
                let dealerLogo, logoData = this.props.dealerDialog.data.dealerLogo;
                let dealerBack, backData = this.props.dealerDialog.data.dealerBack;
                dealerLogo = [{
                    uid     : 1,
                    name    : logoData.split('/').pop(),
                    status  : 'done',
                    url     : logoData
                }];
                dealerBack = [{
                    uid     : 1,
                    name    : backData.split('/').pop(),
                    status  : 'done',
                    url     : backData
                }];
                this.setState({...this.props.dealerDialog.data, dealerLogo, dealerBack});
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if ( this.props.dealerDialog.type === 'new' &&
                !_.isEqual(newDealerState, prevState) )
            {
                this.setState({...newDealerState});
            }
        }
    }

    handleMenuClick = event => {
        this.setState({anchorEl1: event.currentTarget});
    };

    closeComposeDialog = () => {
        this.props.dealerDialog.type === 'edit' ? this.props.closeEditDealerDialog() : this.props.closeNewDealerDialog();
        this.setState({checked: false});
    };

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
        this.props.form.validateFields( (err, values) => {
            if (!err) {
                let newModel = {...model};
                newModel.themeColor = values.themeColor;

                if(this.props.dealerDialog.type === "new") {
                    this.props.addDealer(newModel, values.dealerLogo, values.dealerBack, this.props.closeNewDealerDialog);
                } else{
                    this.props.updateDealer(newModel, this.props.dealerDialog.data.id, this.props.dealerDialog.data.guid, values.dealerLogo, values.dealerBack, this.props.closeEditDealerDialog);
                    // this.props.closeEditDealerDialog();
                }
            }
        } );
    };

    handleCheckedChange = e => {
        this.setState({checked: e.target.checked});
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    render()
    {
        const {dealerDialog, classes, catalogCategory} = this.props;
        const {canSubmit, companyName, contactPerson, address, telephone, visualizerName, email, url, themeColor, dealerLogo, catalog_id, ui_mode, dealerBack, bcc, fromEmail, subject} = this.state;        
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {this.props.dealerDialog.type === "new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );

        const catalogItems = [];
        catalogCategory.forEach(fElement => {
            catalogItems.push(<MenuItem key={fElement.id} value={fElement.id}>{fElement.name}</MenuItem>);
        })

        const uimodeItems = [];
        UI_MODES.forEach(fElement => {
            uimodeItems.push(<MenuItem key={fElement.id} value={fElement.id}>{fElement.name}</MenuItem>);
        })

        return (
            <Dialog
                classes={{
                    paper: classNames(classes.paper, "max-w-lg w-full m-24")
                }}
                onClose={this.closeComposeDialog}
                {...dealerDialog.props}
            >

                {dealerDialog && (
                    <DialogTitle component="div" className="p-0">
                        <AppBar position="static" elevation={1}>
                            <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16">
                                <div className="flex flex-1">
                                    <Typography color="inherit" className="mt-16" variant="h6">
                                        {dealerDialog.type === "new" ? "Register" : "Edit"}
                                    </Typography>
                                </div>
                                <IconButton color="inherit" onClick={this.closeComposeDialog}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>
                )}

                {dealerDialog && (
                    <DialogContent className="p-16 sm:p-24">

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            ref={(form) => this.form = form}
                        >
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="companyName"
                                label="Company Name"
                                validations={{
                                    minLength: 2
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 2.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">person</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : companyName}
                                                   
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="contactPerson"
                                label="Contact Person"
                                validations={{
                                    minLength: 2
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 2.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : contactPerson}
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="address"
                                label="Address"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : address}
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="telephone"
                                label="Telephone"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : telephone}
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="url"
                                label="URL"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                onChange={ (e) => {
                                    this.setState({ visualizerName: getVisualizerName(e.target.value) });
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : url}
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="visualizerName"
                                label="Visualizer Name"
                                validations={{
                                    minLength: 2
                                }}                                
                                validationErrors={{
                                    minLength: 'minLength is less than 2.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>,
                                    readOnly: true,
                                }}
                                variant="outlined"
                                required
                                value={visualizerName}
                            />                            
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="email"
                                label="Company Contact"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : email}
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="fromEmail"
                                label="for visualizer outbound emails"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : fromEmail}
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="bcc"
                                label="bcc to customer or Addo support"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : bcc}
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="text"
                                name="subject"
                                label="Email Subject"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.dealerDialog.type === "new" ? "" : subject}
                            />
                            
                            <SelectFormsy
                                className="w-full mb-36"
                                name="catalog_id"
                                label="Catalogs"
                                required
                                variant="outlined"
                                //multiple
                                value={this.props.dealerDialog.type === "new" ? [] : catalog_id[0]}
                                onChange={ (e) => {
                                    this.setState({ catalog_id: [e.target.value] });
                                }}
                            >
                                {catalogItems}
                            </SelectFormsy>

                            <SelectFormsy
                                className="w-full mb-36"
                                name="ui_mode"
                                label="UI Mode"
                                required
                                variant="outlined"
                                //multiple
                                value={this.props.dealerDialog.type === "new" ? [] : ui_mode}
                                onChange={ (e) => {
                                    this.setState({ ui_mode: e.target.value });
                                }}
                            >
                                {uimodeItems}
                            </SelectFormsy>

                            <Row>
                                <Col span={12}>
                                    <FormItem 
                                        key="themeColor"
                                        {...formItemLayout}
                                        label="Color"
                                    >
                                        {getFieldDecorator("themeColor", {
                                            initialValue: themeColor,
                                            rules: [{ required: true, message: 'Please select color!' }],
                                        })(
                                            (<Input
                                                placeholder="Select Theme Color"
                                                disabled
                                                addonBefore={
                                                    <div id="colorPicker">
                                                        <ColorPicker 
                                                            getCalendarContainer={() => {
                                                                return document.getElementById("colorPicker");
                                                            }}
                                                            onClose={e => { 
                                                                this.setState({themeColor: e.color})
                                                                setFieldsValue({themeColor: e.color})
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                onChange={ (e) => {
                                                    this.setState({themeColor : e.target.value});
                                                }}
                                            />)
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem 
                                        key="dealerLogo"
                                        labelCol={{span: 11}}
                                        wrapperCol={{span: 12}}
                                        label="Logo Image"
                                    >
                                        {getFieldDecorator("dealerLogo", {
                                            initialValue: dealerLogo,
                                            valuePropName: 'fileList',
                                            rules: [{ required: true, message: 'Please upload logo image!' }],
                                            getValueFromEvent: this.normFile,
                                        })(
                                            ( <Upload
                                                listType="picture-card"
                                                className="upload-list-inline"
                                                onRemove={file => {
                                                    this.setState({
                                                        dealerLogo: [],
                                                    });
                                                }}
                                                beforeUpload={(file, fileList) => {
                                                    this.setState({
                                                        dealerLogo: fileList,
                                                    });
                                                    return false;
                                                }}
                                                >
                                                {dealerLogo.length > 0 ? null : uploadButton}
                                            </Upload>)
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <FormItem 
                                        key="dealerBack"
                                        labelCol={{span: 11}}
                                        wrapperCol={{span: 12}}
                                        label="First Background Image"
                                    >
                                        {getFieldDecorator("dealerBack", {
                                            initialValue: dealerBack,
                                            valuePropName: 'fileList',
                                            rules: [{ required: true, message: 'Please upload logo image!' }],
                                            getValueFromEvent: this.normFile,
                                        })(
                                            ( <Upload
                                                listType="picture-card"
                                                className="upload-list-inline"
                                                onRemove={file => {
                                                    this.setState({
                                                        dealerBack: [],
                                                    });
                                                }}
                                                beforeUpload={(file, fileList) => {
                                                    this.setState({
                                                        dealerBack: fileList,
                                                    });
                                                    return false;
                                                }}
                                                >
                                                {dealerBack.length > 0 ? null : uploadButton}
                                            </Upload>)
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="password"
                                name="password"
                                label="Password"
                                validations="equalsField:password-confirm"
                                validationErrors={{
                                    equalsField: 'Please confirm your password'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">vpn_key</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                value = ''
                                required={this.props.dealerDialog.type === "new" ? true : false}
                            />
                            <TextFieldFormsy
                                className="w-full mb-36"
                                type="password"
                                name="password-confirm"
                                label="Confirm Password"
                                validations="equalsField:password"
                                validationErrors={{
                                    equalsField: 'Please confirm your password'
                                }}
                                value = ''
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">vpn_key</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required={this.props.dealerDialog.type === "new" ? true : false}
                            />
                            {dealerDialog.type === 'new' ? (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="mx-auto mt-16 normal-case"
                                    aria-label="REGISTER"
                                    // disabled={!canSubmit}
                                    value="legacy"
                                >
                                    Add
                                </Button>
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="mx-auto mt-16 normal-case"
                                    aria-label="REGISTER"
                                    disabled={!canSubmit}
                                    value="legacy"
                                >
                                    Save
                                </Button>
                            )}
                            

                        </Formsy>
                    </DialogContent>
                )}
            </Dialog>
        );
    }
}


function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        closeEditDealerDialog: Actions.closeEditDealerDialog,
        closeNewDealerDialog : Actions.closeNewDealerDialog,
        addDealer            : Actions.addDealer,
        updateDealer         : Actions.updateDealer,
        removeDealer         : Actions.removeDealer,
        getCatalogCategory   : Actions.getCatalogCategory,
    }, dispatch);
}

function mapStateToProps({dealersApp})
{
    return {
        dealerDialog        : dealersApp.dealers.dealerDialog,
        dealers             : dealersApp.dealers.entities,
        catalogCategory     : dealersApp.dealers.catalogCategory,
    }
}

const DealerDialogComponent = Form.create()(DealerDialog);

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(DealerDialogComponent));
