import axios from 'axios';
import { showMessage } from 'app/store/actions/fuse';
import { API_SERVER_URL } from 'app/main/config';

export const GET_MAINBACKGROUND_DATA = '[URL_GENERATOR] GET MAINBACKGROUND DATA';
export function getMainbackgroundData() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/mainbackground/read/main_background`)
            .then(res => {
                const { mainbackground } = res.data;
                dispatch({
                    type: GET_MAINBACKGROUND_DATA,
                    payload: { mainbackground }
                })
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
            })
    })
}

export const GET_BRAND_DATA = '[URL_GENERATOR] GET BRAND DATA';
export function getBrandData() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/brand/read/brand`)
            .then(res => {
                const { brand } = res.data;
                dispatch({
                    type: GET_BRAND_DATA,
                    payload: { brand }
                })
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
            })
    })
}

export const GET_DEALER_DATA = '[URL_GENERATOR] GET DEALER DATA';
export function getDealerData() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/dealer/get_dealer`)
            .then(res => {
                const dealer = res.data;
                dispatch({
                    type: GET_DEALER_DATA,
                    payload: { dealer }
                })
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
            })
    })
}


export const GET_CATALOG_DATA = '[URL_GENERATOR] GET CATALOG DATA';
export function getCatalogData() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/catalog/read/catalog`)
            .then(res => {
                const { catalog } = res.data;
                dispatch({
                    type: GET_CATALOG_DATA,
                    payload: { catalog }
                })
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
            })
    })
}

export const GET_LAYER_AND_MOLDING_DATA = '[URL_GENERATOR] GET LAYER DATA';
export function getLayerAndMoldingData(backId) {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/mainbackground/read/layer/v2`, { backId, isDirect: true })
            .then(res => {
                const { layerData, moldingData } = res.data;
                dispatch({
                    type: GET_LAYER_AND_MOLDING_DATA,
                    payload: { layerData, moldingData }
                })
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
            })
    })
}
